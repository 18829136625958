const config = {
  apiGateway: {
    REGION: 'us-east-2',
    INTERNAL_API_URL: 'https://3gr5b3r1gb.execute-api.us-east-2.amazonaws.com/prod',
    TARGET_API_URL: 'https://rabpq4zowh.execute-api.us-east-2.amazonaws.com/api',
    TEAMS_APP_URL: 'https://teams.vale.forwoodsafety.com/teams',
    QS_DASHBOARD_URL: 'https://id.vale.forwoodsafety.com/quicksight-login?qs_redirect_uri=https://us-east-2.quicksight.aws.amazon.com/sn/dashboards/2b3c7d5d-2553-4cdc-984b-2aa69d4a22b5&session_timeout_redirect_url=id.vale.forwoodsafety.com/quicksight-login',
    CLIENT_TIMEZONE: 'America/Sao_Paulo'
  },
  reactApp: {
    VERSION: 'targets-1.8.0',
    HOSTNAME: 'https://targets.vale.forwoodsafety.com'
  }
};

export default config;